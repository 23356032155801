import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  COMPANY_STATUS_MANAGEMENT_URL,
  GET_ADD_SUBSCRIPTIONS_URL,
  GET_UPDATE_DELETE_SUBSCRIPTION_URL,
} from "../../constants/api/endpoints"
import { http } from "../../utils/http"
import { AxiosResponse } from "axios"
import { APIPaginatedResponseType, APIPaginationType } from "../../types/api/utils"
import { PricingFormType } from "../../types/PricingForm"
import { SubscriptionType } from "../../types/SubscriptionsType"

// Fetch all subscription
export const fetchSubscriptions = createAsyncThunk<APIPaginatedResponseType<SubscriptionType> | SubscriptionType[], { pageIndex?: number }>(
  "subscriptions/fetchSubscriptions",
  async ({ pageIndex }, thunkApi) => {

    const url = pageIndex ? `${GET_ADD_SUBSCRIPTIONS_URL}?page=${pageIndex}` : GET_ADD_SUBSCRIPTIONS_URL
    return http
      .get<APIPaginatedResponseType<SubscriptionType> | SubscriptionType[]>(url)
      .then((res): APIPaginatedResponseType<SubscriptionType> | SubscriptionType[] => res.data)
      .catch(e => {
        return thunkApi.rejectWithValue({ data: e.data })
      })
  })

// Update an existing subscription
export const asyncUpdateSubscription = createAsyncThunk<SubscriptionType, {
  id?: string,
  data?: Partial<PricingFormType | FormData>,
}>(
  "subscription/asyncUpdateSubscription",
  async ({ id, data }: {
    id?: string,
    data?: Partial<PricingFormType | FormData>,
  }, thunkApi) => {
    return http
      .patch<SubscriptionType>(GET_UPDATE_DELETE_SUBSCRIPTION_URL.replace(":id", `${id}`), data)
      .then((res): SubscriptionType => res.data)
      .catch(e => {
        return thunkApi.rejectWithValue({ data: e.data })
      })
  },
)


// Update an existing company' from subscription
export const asyncManageCompanyStatusFromSubscription = createAsyncThunk<any, {
  companyId: string,
  data?: { todo: string },
}>(
  "subscription/asyncManageCompanyStatusFromSubscription",
  async ({ companyId, data }: {
    companyId: string,
    data?: { todo: string },
  }, thunkApi) => {
    return http
      .post(COMPANY_STATUS_MANAGEMENT_URL.replace(":id", `${companyId}`), data)
      .then(res => res.data)
      .catch(e => {
        return thunkApi.rejectWithValue({ data: e.data })
      })
  },
)

// Delete an existing subscription
export const deleteAsyncSubscription = createAsyncThunk<AxiosResponse, { id: string }>(
  "subscription/deleteAsyncSubscription",
  async ({ id }: { id: string }, thunkApi) => {
    return http
      .delete<AxiosResponse>(GET_UPDATE_DELETE_SUBSCRIPTION_URL.replace(":id", id))
      .then(res => res.data)
      .catch(e => {
        return thunkApi.rejectWithValue({ data: e.data })
      })
  },
)


// initial state
const initialState: {
  subscriptions: SubscriptionType[],
  pagination?: APIPaginationType
  subscriptionsStatus: string,
  subscriptionsErrors?: any,

  addSubscriptionStatus: string,
  addSubscriptionErrors?: any,

  deleteSubscriptionErrors?: any,
  deleteSubscriptionStatus: string,
} = {
  subscriptions: [],
  subscriptionsStatus: "idle",
  addSubscriptionStatus: "idle",
  deleteSubscriptionStatus: "idle",
}

const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {},

  extraReducers(builder) {
    // get  all subscriptions
    // Todo handle pagination
    builder
      .addCase(fetchSubscriptions.pending, (state) => {
        state.subscriptionsStatus = "loading"
      })
      .addCase(fetchSubscriptions.fulfilled, (state, action) => {
        state.subscriptionsStatus = "succeeded"
        if ("results" in action.payload) {
          const { results, ...pagination } = action.payload
          state.subscriptions = results
          state.pagination = pagination
        } else {
          state.subscriptions = action.payload
        }
      })
      .addCase(fetchSubscriptions.rejected, (state, action) => {
        state.subscriptionsStatus = "failed"
        state.subscriptionsErrors = action.error
      })
    builder
      .addCase(asyncUpdateSubscription.pending, (state) => {
        state.subscriptionsStatus = "loading"
      })
      .addCase(asyncUpdateSubscription.fulfilled, (state, action) => {
        state.subscriptionsStatus = "succeeded"
        let index = state.subscriptions.findIndex(subscription => subscription.id === action.meta.arg.id)
        if (index >= 0) {
          state.subscriptions.splice(index, 1)
          state.subscriptions.unshift(action.payload)
        }

      })
      .addCase(asyncUpdateSubscription.rejected, (state, action) => {
        state.subscriptionsStatus = "failed"
        state.subscriptionsErrors = action.error
      })

    builder
      .addCase(asyncManageCompanyStatusFromSubscription.pending, (state) => {
        state.subscriptionsStatus = "loading"
      })
      .addCase(asyncManageCompanyStatusFromSubscription.fulfilled, (state, action) => {
        state.subscriptionsStatus = "succeeded"
        state.subscriptions = state.subscriptions.map((subscription) => {
          if (subscription.company.id === action.meta.arg.companyId) {
            return { ...subscription, company: action.payload }
          }
          return subscription
        })

      })
      .addCase(asyncManageCompanyStatusFromSubscription.rejected, (state, action) => {
        state.subscriptionsStatus = "failed"
        state.subscriptionsErrors = action.error
      })

    builder
      .addCase(deleteAsyncSubscription.pending, (state) => {
        state.deleteSubscriptionStatus = "loading"
      })
      .addCase(deleteAsyncSubscription.fulfilled, (state, action) => {
        state.subscriptionsStatus = "succeeded"
        state.subscriptions = state.subscriptions.filter((subscription) => subscription.id !== action.meta.arg.id)
      })
      .addCase(deleteAsyncSubscription.rejected, (state, action) => {
        state.deleteSubscriptionStatus = "failed"
        state.deleteSubscriptionErrors = action.error
      })

  },
})

export default subscriptionsSlice.reducer

