// Keycloak
export const KEYCLOAK_BASE_URL = `${import.meta.env.VITE_KEYCLOAK_BASE_URL}`

export const KEYCLOAK_AUTH_ACCESS_CLIENT_ID = `${import.meta.env.VITE_KEYCLOAK_AUTH_ACCESS_CLIENT_ID}`
export const KEYCLOAK_AUTH_ACCESS_GRANT_TYPE = `${import.meta.env.VITE_KEYCLOAK_AUTH_ACCESS_GRANT_TYPE}`
export const KEYCLOAK_AUTH_ACCESS_REALM = `${import.meta.env.VITE_KEYCLOAK_AUTH_ACCESS_REALM}`

export const KEYCLOAK_AUTH_TOKEN_KEY = "KEYCLOAK_AUTH_TOKEN_KEY"

// Clients Service Endpoints
export const CLIENT_SERVICE_BASE_URL = `${import.meta.env.VITE_CLIENT_SERVICE_BASE_URL}`

// Companies
export const GET_CREATE_COMPANIES_URL = CLIENT_SERVICE_BASE_URL + "companies/"
export const GET_UPDATE_DELETE_COMPANY_URL = CLIENT_SERVICE_BASE_URL + "companies/:id/"
export const GET_ADD_COMPANY_SUBSCRIPTION_URL = CLIENT_SERVICE_BASE_URL + "companies/:id/subscriptions/"
export const COMPANY_INVITATION_REQUEST_URL = CLIENT_SERVICE_BASE_URL + "companies/:id/invite/"
export const GET_COMPANY_ACTIVE_USER_COUNT_URL = CLIENT_SERVICE_BASE_URL + "companies/:id/active_users_count/"
export const GET_COMPANY_ACTIVE_SUBSCRIPTION_URL = CLIENT_SERVICE_BASE_URL + "companies/:id/active-subscription/"
export const COMPANY_STATUS_MANAGEMENT_URL = CLIENT_SERVICE_BASE_URL + "companies/:id/manage-company-status/"


// Plans
export const GET_ADD_PLANS_URL = CLIENT_SERVICE_BASE_URL + "plans/"
export const GET_UPDATE_DELETE_PLAN_URL = CLIENT_SERVICE_BASE_URL + "plans/:id/"

// Subscriptions
export const GET_ADD_SUBSCRIPTIONS_URL = CLIENT_SERVICE_BASE_URL + "subscriptions/"
export const GET_UPDATE_DELETE_SUBSCRIPTION_URL = CLIENT_SERVICE_BASE_URL + "subscriptions/:id/"
export const GET_SUBSCRIPTIONS_STATISTICS_URL = CLIENT_SERVICE_BASE_URL + "subscriptions/statistics/"

// Payments
export const GET_ADD_PAYMENTS_URL = CLIENT_SERVICE_BASE_URL + "payments/"
export const GET_UPDATE_DELETE_PAYMENT_URL = CLIENT_SERVICE_BASE_URL + "payments/:id/"

// Support Members
export const GET_ADD_SUPPORT_MEMBERS_URL = CLIENT_SERVICE_BASE_URL + "support-members/"
export const GET_UPDATE_DELETE_SUPPORT_MEMBERS_URL = CLIENT_SERVICE_BASE_URL + "support-members/:id/"
export const INVITE_SUPPORT_MEMBER_IN_COMPANY_URL = CLIENT_SERVICE_BASE_URL + "support-members/:id/invite-in-company/"

// Representatives
export const GET_ADD_REPRESENTATIVES_URL = CLIENT_SERVICE_BASE_URL + "representatives/"
export const GET_UPDATE_DELETE_REPRESENTATIVE_URL = CLIENT_SERVICE_BASE_URL + "representatives/:id/"

